// Wordpress CSS
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

//Chakra UI
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IconCaretLeft, IconCaretRight } from "@tabler/icons"
import { graphql, navigate } from "gatsby"

import { Dots } from "../components/dots"
//Base 
import Layout from "../layout"
//React
import React from "react"
import Seo from "../components/seo"
import { getWordCount } from "../components/helpers"
import parse from "html-react-parser"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const topics = post.topics.nodes

  const headings = post.blocks
  const counter = 0

  const featuredImage = {
    data: post.featuredImage?.node?.sourceUrl,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const headerText = {
    text: post.post?.headerText
  }

  function isHeaderTextNull() {
      if (headerText.text === null) {
    return (<></>)
  } else {
    return (<><Box fontSize="20px" fontWeight="700"> {parse(headerText.text)} </Box>  <Divider borderBottomWidth="0px" height="2px" width="200px" backgroundColor="#D7D8E0" opacity="1" my="20"></Divider></>)
  }
  }

  function isReferencesNull() {
    if (post.post.references == null) {
      return (<></>)
    } else {
      return (<Text fontWeight="700"> {parse(post.post.references)} </Text>)
    }
  }

  return (
    <Layout>
      <Seo title={post.seo.title} description={post.seo.metaDesc} keywords={post.seo.focuskw}/>
      <Box height="720px" background={"linear-gradient(180deg, rgba(11, 17, 40, 0) 0%, #0B1128 94.27%), url(" + featuredImage.data + ")" } backgroundBlendMode="multiply, normal" backgroundRepeat="no-repeat" backgroundSize="cover">
        <Container maxW="container.xl" height="inherit" p="0" >
          <VStack maxW="container.md" pb="120px" pl="40px" spacing="3" height="inherit" alignItems="flex-start" justifyContent="end">
            <Text color="base.50" margin="0px !important" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium">TOPICS</Text>
            <HStack mt="0px !important" mb="16px !important">
             
              {topics.map((topic, index, array) => {
                const lastElement = array.length - 1;
         
                if (lastElement === index) {
                  return <Text color="base.50" mb="0px !important" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {topic.name}</Text>
                } else {
                 return <>
                    <Text color="base.50" mb="0px !important" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {topic.name}</Text>
                    <Dots></Dots>
                  </>
                }
              })}
              </HStack>
              <Heading as="h1" fontSize="4xl" margin="0px !important" color="base.50" fontWeight="700" lineHeight="40px" >{post.title} </Heading>
              <Stack direction="row" align="center">
                {/* <Text color="base.50" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium" >{post.author.node.name}</Text>
                <Dots></Dots> */}
                <Text color="base.50" mb="0px !important" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium" > {post.date} </Text>       
                <Dots></Dots>
                <Text color="base.50" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {getWordCount(post.content) + " min read"}  </Text>
              </Stack> 
          </VStack>
        </Container>
      </Box>

      <Container maxW="container.xl" py="120px" px="40px">
        {/* CONTENT LEFT */}
        <Grid  templateColumns='repeat(3, 1fr)' gap={28} >
          <GridItem colSpan={{base:'3', lg:'2'}}>
            <div class="s9-widget-wrapper"></div>

            {isHeaderTextNull()}
            
            <Text fontWeight="700"> {parse(post.content)} </Text>
            {/* Contact Button */}
            <Box display="flex" justifyContent="center" mt="10">
              <Button onClick={() => { navigate("/contact/") }} aria-label="Link to contact us page" variant="solid" backgroundColor="#3554CA" color="#fff" w="full">Contact Us</Button>
            </Box>

            {isReferencesNull()}
            {/* <Text fontWeight="700"> {parse(post.post.references)} </Text> */}
            <Divider borderBottomWidth="0px" height="2px" backgroundColor="#D7D8E0" opacity="1" mt="20"></Divider>

            {/* PREVIOUS AND NEXT ARTICLE */}
            <Flex direction="row" alignItems="center" justifyContent="space-between"  pt="32px">
               <VStack alignItems="flex-start" fontSize="14px">
                {previous && (<Link href={"/insights" + previous.uri} aria-label={previous.title} rel="prev" color="#2D3748 !important" textAlign="left" fontWeight="700">{parse(previous.title)}</Link>)}
                {previous && (<Link href={"/insights" + previous.uri} aria-label={previous.title}><Button leftIcon={<IconCaretLeft height="16px" width="16px" alignItems="center"/>} colorScheme='teal' variant='outline'>Previous</Button></Link>)}
              </VStack>
              <VStack alignItems="flex-end" fontSize="14px">
                {next && (<Link href={"/insights" + next.uri} aria-label={next.title} rel="next" color="#2D3748 !important" textAlign="right" fontWeight="700">{parse(next.title)}</Link>)}
                {next && (<Link href={"/insights" + next.uri} aria-label={next.title}><Button rightIcon={<IconCaretRight height="16px" width="16px" alignItems="center"/>} colorScheme='teal' variant='outline'>Next</Button></Link>)}
              </VStack>
            </Flex>
          </GridItem>
         
           {!isMobile && (
            <GridItem colSpan={1} position="sticky" top="112px" alignSelf="start">
              <Text color="gray.600" mb="0px !important" fontFamily="Gotham Medium" fontWeight="bold" pb="16px"> Table of Contents </Text>
              <List className="toc-list">
                {headings.map((h, i = 0) => {
                
                if (h.tagName === "h1" || h.tagName === "h2") {
                  return <Link href={"#heading" + i++}><ListItem color="gray.600" fontWeight="700" pb="16px" fontSize="14px" >{h.innerHtml}</ListItem> </Link>  
                  } else if( h.tagName === "h3" ){
                    return <Link href={"#heading" + i++}><ListItem color="gray.600" fontWeight="700" pb="16px" pl={6} fontSize="14px">{h.innerHtml}</ListItem> </Link>
                  } else {
                  <></>
                  }
                })}
              </List>
            </GridItem>
            )}
        </Grid>
      </Container>    
    </Layout>
  )
}

export default BlogPostTemplate

//GRAPHQL
export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      blocks {
        tagName
        innerHtml
      }
      post {
        headerText
        references
      }
      author {
        node {
          name
        }
      }
      topics {
          nodes {
            name
          }
        }
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }

      seo {
        metaDesc
        metaKeywords
        title
        focuskw
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
